import { createContext, useCallback, useState } from "react";
import { EMPTY_USER, getUser } from "@/services/auth";

export const AuthContext = createContext();

const DEFAULT_USER = {
  ...getUser()
};

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(DEFAULT_USER);

  const setUserInfo = useCallback((data) => {
    setUser({
      ...data
    });
  }, [])

  const logout = useCallback(() => {
    setUser(EMPTY_USER);
  }, []);

  return (
    <AuthContext.Provider value={{ ...user, setUserInfo, logout }}>
      {children}
    </AuthContext.Provider>
  );
}

export default AuthProvider