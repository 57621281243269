import useAuth from "@/hooks/useAuth";
import { useEffect } from "react";

const Blank = ({ children, isPublic }) => {
  const { isLoggedIn, handleLogout } = useAuth();

  useEffect(() => {
    if (!isPublic && !isLoggedIn) {
      const isAutomaticLoggout = true;
      handleLogout(isAutomaticLoggout);
    }
  }, [isPublic, isLoggedIn, handleLogout]);


  return children
}

export default Blank
